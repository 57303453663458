import styled from 'styled-components'

import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

interface Props extends ContextThemeProps {
  type?: 'error' | 'warning' | 'success'
}

const getBackground = (props: Props) => {
  switch (props.type) {
    case 'error':
      return getStyle('pill-error-background')
    case 'warning':
      return getStyle('pill-warning-background')
    case 'success':
      return getStyle('pill-success-background')
    default:
      return getStyle('pill-default-background')
  }
}

const getColor = (props: Props) => {
  switch (props.type) {
    case 'error':
      return getStyle('pill-error-color')
    case 'warning':
      return getStyle('pill-warning-color')
    case 'success':
      return getStyle('pill-success-color')
    default:
      return getStyle('pill-default-color')
  }
}

const Pill = styled.span<Props>`
  align-self: center;
  background-color: ${getBackground};
  border-radius: 20px;
  color: ${getColor};
  display: inline-block;
  font-size: 11px;
  font-weight: ${font('weight', 'bold')};
  padding: 0 10px;
  text-align: center;
  text-transform: uppercase;
`

export default Pill
