import styled from 'styled-components'

import Transition from 'happitu/src/components/Animations/Transition'
import getStyle from 'happitu/src/getStyle'
import { font, metric, zIndex } from 'theme'

// animation
export const popInAnimation = {
  animate: {
    x: '-50%',
    y: '-50%',
    scale: 1,
    opacity: 1,
  },
  initial: {
    x: '-50%',
    y: '-10%',
    scale: 0.8,
    opacity: 0,
  },
  exit: {
    x: '-50%',
    y: '-50%',
    scale: 0.8,
    opacity: 0,
  },
}

// Wrapper
export const Wrapper = styled(Transition)`
  background: ${getStyle('modal-background')};
  border-radius: ${metric('largeBorderRadius')};
  box-shadow: ${getStyle('modal-boxShadow')};
  left: 50%;
  max-width: 400px;
  padding: 2em;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  width: 100%;
  z-index: ${zIndex('modal')};

  h2 {
    font-weight: ${font('weight', 'medium')};
    margin-bottom: 1em;
  }

  footer {
    border-top: 1px solid ${getStyle('border-color')};
    padding-top: 1em;
  }
`
