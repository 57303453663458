import { motion, AnimatePresence } from 'framer-motion'
import React from 'react'

const AnimateNumber = ({ value }: { value: number }) => {
  return (
    <AnimatePresence>
      <motion.div
        key={value}
        initial={{ transform: 'translate3d(0,-100%,0)', opacity: 0 }}
        animate={{ transform: 'translate3d(0,0px,0)', opacity: 1 }}
        exit={{ transform: 'translate3d(0,100%,0)', opacity: 0, position: 'absolute' }}
      >
        {value}
      </motion.div>
    </AnimatePresence>
  )

  // const transitions = useTransition(count, count, {
  //   from: { transform: 'translate3d(0,-100%,0)', opacity: 0 },
  //   enter: { transform: 'translate3d(0,0px,0)', opacity: 1 },
  //   leave: { transform: 'translate3d(0,100%,0)', opacity: 0, position: 'absolute' },
  // })
  // return (
  //   <span style={{ position: 'relative' }}>
  //     {transitions.map(({ item, props, key }) => (
  //       <animated.div key={key} style={props}>
  //         {item}
  //       </animated.div>
  //     ))}
  //   </span>
  // )
}

export default AnimateNumber
