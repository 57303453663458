import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import {
  ErrorFigure,
  FieldFigure,
  NextFieldWrapper,
  InsetLabelContent,
  InputWrapper,
} from '../../Fields/FieldUtils'

import Option from 'happitu/src/components/Lists/ListItems/Option'
import Select from 'happitu/src/components/Select/Select'
import { SelectWrapper } from 'happitu/src/components/Select/Select.styles'

interface Props<V = string | string[]> extends FieldRenderProps<V, HTMLInputElement> {
  footnote?: string
  label?: string
  options: {
    label: string
    value: string
  }[]
  autoSelect?: boolean
}

const hasValue = (value: string | string[]) => {
  return Array.isArray(value) ? value.length > 0 : !!value
}

const SelectField = (props: Props) => {
  const { label, footnote, meta, input, options, ...otherProps } = props
  return (
    <NextFieldWrapper {...otherProps}>
      <InputWrapper>
        <Select
          disabled={props.disabled}
          multiple={input.multiple}
          onSelect={input.onChange}
          search={options.length > 10}
          value={input.value}
        >
          {options.map((option, i) => (
            <Option {...option} key={i} />
          ))}
        </Select>
        {!!label && (
          <InsetLabelContent
            style={!hasValue(input.value) ? { transform: 'translateY(0)' } : {}}
          >
            {label}
          </InsetLabelContent>
        )}
      </InputWrapper>
      {props.meta.touched && props.meta.error ? (
        <ErrorFigure>{meta.error}</ErrorFigure>
      ) : (
        <FieldFigure>{footnote}</FieldFigure>
      )}
    </NextFieldWrapper>
  )
}

export default styled(SelectField)`
  ${SelectWrapper} {
    width: 100%;
    padding: 8px 10px;
    font-size: 1.143em;

    ${ifProp(
      'label',
      css`
        min-height: calc(16px + 20px + 1em);
        padding-top: 24px;
      `,
      css`
        min-height: 2em;
      `,
    )};
  }
`
