import autobind from 'autobind-decorator'
import * as React from 'react'
import styled from 'styled-components'

import { Button, Header, Wrapper } from './Tag.styles'

interface Props {
  className?: string
  onClick?: Function
  onRemove?: Function
  readOnly?: boolean
  size?: string
  style?: React.CSSProperties
  tag: TagRecord
  wrap?: boolean
}

class Tag extends React.PureComponent<Props> {
  @autobind
  public handleClick(event: React.SyntheticEvent) {
    const {
      onClick,
      tag: { id },
    } = this.props
    if (typeof onClick === 'function') onClick(id, event)
  }

  @autobind
  public handleRemove(event: React.SyntheticEvent) {
    const {
      onRemove,
      tag: { id },
    } = this.props
    if (typeof onRemove === 'function') onRemove(id, event)
  }

  public render() {
    const {
      className,
      onClick,
      onRemove,
      readOnly,
      tag: { color, name },
      style,
    } = this.props
    return (
      <Wrapper
        direction="ltr"
        color={color}
        className={className}
        size={this.props.size}
        style={style}
      >
        <Header onClick={onClick && this.handleClick} shouldWrap={this.props.wrap}>
          {name}
        </Header>
        {!readOnly && onRemove && <Button onClick={this.handleRemove}>&times;</Button>}
      </Wrapper>
    )
  }
}

export default styled(Tag)``
