// components
import { FieldValidator } from 'final-form'
import React, { createElement, FunctionComponent, MouseEvent } from 'react'
import { Form, Field } from 'react-final-form'

// styles
import { Wrapper, popInAnimation } from './InputFormModal.styles'

import Mask from 'happitu/src/components/Modal/Mask'
import { Button } from 'happitu/src/components/button'
import { TextField } from 'happitu/src/components/final-form-fields'
import useHotkey, { Hotkey } from 'happitu/src/hooks/useHotkey'

//  =============================================== TYPES ===============================================

export interface FormField {
  name: string
  label: string
  component?: FunctionComponent<any>
  options?: Array<{
    label: string
    value: string
  }>
  initialValue?: string
  fieldValidator?: FieldValidator<any>
  placeholder?: string
  type?: 'group' | 'text' | 'password'
  multiple?: boolean
}

export interface InputFormModalProps<T = Record<string, any>> {
  isOpen: boolean
  title: string
  initialValues: Partial<T>
  fields: FormField[]
  submitLabel?: string
  onSubmit: (formState: T) => void
  onClose: () => void
}

export const InputFormModal = (props: InputFormModalProps) => {
  useHotkey(Hotkey.Close, () => props.onClose, true)
  type FormState = typeof props.initialValues

  const handleClose = (e: MouseEvent) => {
    e.stopPropagation()
    props.onClose()
  }

  const handleSubmit = async (formState: Required<FormState>) => {
    try {
      await props.onSubmit(formState)
      props.onClose()
    } catch (e) {
      return e
    }
  }

  return (
    <>
      <Mask isVisible={props.isOpen} onClick={handleClose} />
      <Wrapper isVisible={props.isOpen} transition={popInAnimation}>
        <h2>{props.title}</h2>
        <Form onSubmit={handleSubmit} initialValues={props.initialValues}>
          {({ handleSubmit, submitting, submitErrors }) => (
            <form onSubmit={handleSubmit}>
              {submitErrors}

              {props.fields.map((field, key) => {
                return field.type === 'group' && field.component ? (
                  createElement(field.component, {
                    ...field,
                    key: key,
                  })
                ) : (
                  <Field
                    key={key}
                    placeholder={field.placeholder}
                    name={field.name}
                    label={field.label}
                    type={field.type || 'text'}
                    component={field.component || TextField}
                    options={field.options}
                    validate={field.fieldValidator}
                    autoFocus={key === 0}
                    multiple={field.multiple}
                  />
                )
              })}
              <footer>
                <Button
                  primary
                  disabled={!props.isOpen || submitting}
                  type="submit"
                  loading={submitting}
                  loadingLabel="Saving..."
                >
                  {!!props.submitLabel ? props.submitLabel : 'Submit'}
                </Button>
                <Button ghost type="button" onClick={handleClose}>
                  Cancel
                </Button>
              </footer>
            </form>
          )}
        </Form>
      </Wrapper>
    </>
  )
}
