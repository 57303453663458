import React, { ComponentProps } from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'

import { FieldRow } from '../../Fields/FieldUtils'
import StateSelectField from '../fields/StateSelectField'
import TextField from '../fields/TextField'

import { requiredValidator } from 'happitu/src/constants/validators'

interface Props extends ComponentProps<'div'> {
  name: string
}

const AddressFieldGroup = ({ name, ...props }: Props) => {
  return (
    <div {...props}>
      <Field
        component={TextField}
        label="Address"
        name={`${name}.line1`}
        validate={requiredValidator('Please enter an address.')}
      />

      <FieldRow>
        <Field component={TextField} label="Apt, Unit, Suite" name={`${name}.line2`} />

        <Field
          component={TextField}
          label="City"
          name={`${name}.city`}
          validate={requiredValidator('Please enter a city.')}
        />
      </FieldRow>

      <FieldRow>
        <Field
          component={StateSelectField}
          name={`${name}.state`}
          label="Choose a State"
          className="state"
          validate={requiredValidator('Please enter a state.')}
        />

        <Field
          component={TextField}
          label="Postal Code"
          name={`${name}.postalCode`}
          style={{ maxWidth: '200px' }}
          validate={requiredValidator('Please enter a postal code.')}
        />
      </FieldRow>
    </div>
  )
}

export default styled(AddressFieldGroup)``
