import styled, { css } from 'styled-components'
import { ifNotProp, switchProp } from 'styled-tools'

import Box from 'happitu/src/components/box'
import getStyle from 'happitu/src/getStyle'
import { fontColorByBackground } from 'happitu/src/helpers/domHelper'
import { font } from 'theme'

export const Header = styled.div<{ shouldWrap?: boolean }>`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
  font-weight: ${font('weight', 'medium')};
  padding: 0 8px;

  ${ifNotProp(
    'shouldWrap',
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `,
  )}
`

interface WrapperProps {
  color: string
  size?: string
}

export const Wrapper = styled(Box)<WrapperProps>`
  color: ${getStyle('text-sub-color')};
  display: inline-flex;
  margin: 3px;
  padding: 0 10px;
  line-height: 1.5;

  ${ifNotProp(
    'shouldWrap',
    css`
      white-space: nowrap;
    `,
  )}

  &:hover {
    border-color: ${getStyle('border-color')};
  }

  &:before {
    background: ${({ color }) => color};
    border-radius: 25px;
    content: '';
    display: inline-block;
    flex-shrink: 0;
    height: 8px;
    width: 8px;
  }

  ${switchProp('size', {
    small: css`
      ${/* sc-sel */ Header} {
        padding: 3px 6px;
      }
    `,
  })}
`

export const Button = styled.div`
  color: ${({ color }) => fontColorByBackground(color)};
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 10px;

  &:hover {
    background: ${getStyle('button-base-hover-background')};
  }
`
