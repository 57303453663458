import React from 'react'

import { FieldProps } from '../FinalFormFields.types'

import { Params, Param } from './NewPasswordField.styles'
import PasswordField from './PasswordField'

import { goodPasswordHelper, PasswordParam } from 'happitu/src/constants/validators'

interface Props extends FieldProps<string> {
  passwordParams: PasswordParam[]
}

const NewPasswordField = ({ passwordParams, ...props }: Props) => {
  const warning = goodPasswordHelper(props.input.value)
  return (
    <div>
      <PasswordField {...props} />
      <Params>
        {passwordParams.map((param) => {
          return (
            <Param key={param.key} valid={(warning || {})[param.key]}>
              {param.label}
            </Param>
          )
        })}
      </Params>
    </div>
  )
}

export default NewPasswordField
