import React, { CSSProperties } from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'

import { RadioButton } from 'happitu/src/components/Fields'
import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

interface Props {
  description?: string
  label: string
  name: string
  style?: CSSProperties
  value: string
}

// NOTE: This is a break from our typical pattern, as this component renders the final-form field instead of being passed to one.
// We could eventually either refactor this component or create a wrapper so we can handle validation for multiple radio buttons.
const RadioField = (props: Props) => (
  <RadioLabel style={props.style}>
    <Field
      name={props.name}
      render={({ input: { onChange, onBlur, checked, value } }) => (
        <RadioButton {...{ onChange, onBlur, checked, value }} />
      )}
      type="radio"
      value={props.value}
    />
    <div>
      <h4>{props.label}</h4>
      {!!props.description && <p>{props.description}</p>}
    </div>
  </RadioLabel>
)

export default RadioField

const RadioLabel = styled.label`
  display: flex;
  margin-bottom: 0.75em;

  h4 {
    font-weight: ${font('weight', 'medium')};
  }

  p {
    color: ${getStyle('text-sub-color')};
    margin-bottom: 0;
  }
`
