import React, { ComponentProps, useRef } from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'
import { switchProp } from 'styled-tools'

import { Mask } from './Modal.styles'
import { BaseModalProps } from './Modal.types'

import { isMenuOpen } from 'happitu/src/components/menus'
import getStyle from 'happitu/src/getStyle'
import useHotkey, { Hotkey } from 'happitu/src/hooks/useHotkey'

const Modal = ({
  isVisible,
  children,
  onClose,
  ...props
}: ComponentProps<'div'> & BaseModalProps) => {
  const maskRef = useRef<HTMLDivElement>(null)
  const root = document.getElementById('portal-root')

  const handleClose = () => {
    if (isVisible && !isMenuOpen()) {
      onClose()
    }
  }

  useHotkey(Hotkey.Close, handleClose, true, [isVisible])

  if (!isVisible) return null
  return root
    ? createPortal(
        <Mask ref={maskRef} onMouseDown={handleClose}>
          <div {...props} onMouseDown={(e) => e.stopPropagation()}>
            {children}
          </div>
        </Mask>,
        root,
      )
    : null
}

export default styled(Modal)`
  background: ${getStyle('modal-background')};
  border-radius: ${getStyle('modal-border-radius')};
  box-shadow: ${getStyle('modal-boxShadow')};
  left: 50%;
  width: calc(100% - 4em);
  position: absolute;
  top: 10%;
  transform: translateX(-50%);
  /* NOTE: contact requires no padding */
  padding: 0;
  display: grid;
  grid-row-gap: 1rem;

  ${switchProp(
    'size',
    {
      small: css`
        max-width: 420px;
      `,
      large: css`
        max-width: 1150px;
      `,
    },
    css`
      max-width: 800px;
    `,
  )}
`
