import { FORM_ERROR } from 'final-form'
import React, { ReactNode } from 'react'
import { Field, Form } from 'react-final-form'

import Modal from '../Modal'

import { Notice } from 'happitu/src/components/Fields'
import { Button } from 'happitu/src/components/button'
import { TextField } from 'happitu/src/components/final-form-fields'
import { requiredValidator } from 'happitu/src/constants/validators'
import createErrorHandler, { APIError } from 'happitu/src/createErrorHandler'

interface Props {
  additionalFields?: ReactNode
  infoNoticeLabel?: ReactNode
  initialValues?: Record<string, string>
  isVisible: boolean
  loadingLabel?: string
  onClose: () => void
  onSubmit: (formValues: { password: string }) => any
  submitLabel?: string
}

const getErrorMessage = createErrorHandler({
  session: {
    'incorrect-password': `Sorry, that password isn't right. Please try again.`,
  },
  request: {
    'incorrect-password': `Sorry, that password isn't right. Please try again.`,
  },
})

const PasswordPrompt = ({
  infoNoticeLabel = 'Confirm your password to continue.',
  initialValues = {},
  loadingLabel = 'Submitting...',
  isVisible,
  onClose,
  onSubmit,
  submitLabel = 'Confirm Password',
  additionalFields,
}: Props) => {
  const handleSubmit = (formValues: { password: string }) => {
    return onSubmit(formValues).catch((errors: APIError[]) => {
      return {
        [FORM_ERROR]: getErrorMessage(errors),
      }
    })
  }

  return (
    <Modal isVisible={isVisible} onClose={onClose} size="small">
      <Form onSubmit={handleSubmit} initialValues={{ ...initialValues, password: '' }}>
        {({ handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit} style={{ padding: '3em' }}>
            {!submitError ? <Notice type="info">{infoNoticeLabel}</Notice> : submitError}
            <div style={{ display: 'grid' }}>
              {additionalFields}
              <Field
                autoFocus
                component={TextField}
                label="Your password"
                name="password"
                type="password"
                validate={requiredValidator('Please enter your password')}
              />
              <Button
                primary
                type="submit"
                loading={submitting}
                loadingLabel={loadingLabel}
              >
                {submitLabel}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </Modal>
  )
}

export default PasswordPrompt
