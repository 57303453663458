import React, { useMemo } from 'react'
import { FieldRenderProps } from 'react-final-form'

import SelectField from './SelectField'

import { US_STATES } from 'happitu/src/constants/locations'

type Props = FieldRenderProps<string, HTMLInputElement>

const StateSelectField = (props: Props) => {
  const options = useMemo(
    () => US_STATES.map((state) => ({ label: state[1], value: state[0] })),
    [],
  )
  return <SelectField {...props} options={options} />
}

export default StateSelectField
