import React, { ComponentProps, MouseEventHandler, MouseEvent } from 'react'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import { IconButton } from 'happitu/src/components/Buttons'
import getStyle from 'happitu/src/getStyle'
import { icomoon } from 'happitu/src/themes/style-utils'
import { font, metric } from 'theme'

interface Props extends Omit<ComponentProps<'div'>, 'onClick'> {
  onClick?: MouseEventHandler<HTMLButtonElement>
  warn?: boolean
}

const Badge = ({ onClick, children, ...props }: Props) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (!onClick) return
    // This was added for cases where a Badge is inside of a label
    // Current use-case: BulkEmail
    e.preventDefault()
    onClick(e)
  }

  return (
    <div {...props}>
      <div role="label">{children}</div>
      {onClick && <IconButton icon="close-thin" ghost onClick={handleClick} />}
    </div>
  )
}

export default styled(Badge).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
})`
  align-items: center;
  border-radius: ${metric('largeBorderRadius')};
  border: 1px solid;
  display: inline-flex;
  font-size: 0.9em;
  font-weight: ${font('weight', 'medium')};
  max-width: 100%;
  padding: 0.25em 0.5em;

  > [role='label'] {
    word-wrap: break-word;
    word-break: break-all;
    display: inline-block;
  }

  ${ifProp(
    'warn',
    css`
      background: ${getStyle('badge-warn-background')};
      border-color: ${getStyle('badge-warn-borderColor')};
      border-style: dashed;

      &:before {
        ${icomoon};
        color: ${getStyle('badge-warn-borderColor')};
        content: '\\e919';
        margin-right: 0.25em;
      }
    `,
    css`
      background: ${getStyle('badge-default-background')};
      border-color: ${getStyle('badge-default-borderColor')};
    `,
  )}

  ${IconButton} {
    font-size: 0.8rem;
    margin-left: 5px;
    min-height: 1.1rem;
    min-width: 1.1rem;
    padding: 3px;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }

    &:active {
      background: rgba(0, 0, 0, 0.2);
    }
  }
`
