import React from 'react'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import {
  ErrorFigure,
  FieldFigure,
  NextFieldWrapper,
  InsetLabelContent,
  InputWrapper,
} from '../../Fields/FieldUtils'
import { FieldProps } from '../FinalFormFields.types'

import { RecordSelectorProps } from 'happitu/src/components/Lists'
import { RecordSelect } from 'happitu/src/components/Select'
import { Anchor } from 'happitu/src/components/menus'

interface Props
  extends FieldProps<string | string[]>,
    Omit<RecordSelectorProps, 'onSelect' | 'value'> {
  menuAnchor?: Anchor
}

const hasValue = (value: string | string[] | undefined) => {
  return Array.isArray(value) ? value.length > 0 : !!value
}

const RecordSelectField = (props: Props) => {
  const { label, footnote, meta, input } = props
  return (
    <NextFieldWrapper invalid={meta.touched && meta.error} className={props.className}>
      <InputWrapper as="div">
        <RecordSelect
          collectionName={props.collectionName}
          conjunction={props.conjunction}
          createPlaceholder={props.createPlaceholder}
          disabled={props.disabled}
          filters={props.filters}
          highlightOptions={props.highlightOptions}
          icon={props.icon}
          menuAnchor={props.menuAnchor}
          multiple={input.multiple}
          name={input.name}
          onCreate={props.onCreate}
          onSelect={input.onChange}
          placeholder={props.placeholder}
          searchPlaceholder={props.searchPlaceholder}
          useImpression={props.useImpression}
          value={input.value}
        />
        <InsetLabelContent
          style={
            !hasValue(input.value) && !props.placeholder
              ? { transform: 'translateY(0)' }
              : {}
          }
        >
          {label}
        </InsetLabelContent>
      </InputWrapper>
      {props.meta.touched && props.meta.error ? (
        <ErrorFigure>{meta.error}</ErrorFigure>
      ) : (
        <FieldFigure>{footnote}</FieldFigure>
      )}
    </NextFieldWrapper>
  )
}

export default styled(RecordSelectField)`
  ${RecordSelect} {
    font-size: 1.143em;
    line-height: 1.4;
    padding: 8px 10px;
    padding-right: 2em;
    width: 100%;

    ${ifProp(
      'label',
      css`
        min-height: calc(16px + 20px + 1em);
        padding-top: 24px;
      `,
      css`
        min-height: 2em;
      `,
    )};
  }
`
