import styled from 'styled-components'

import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

export const MODAL_PADDING = '3rem'

export const Mask = styled.div`
  background: ${getStyle('modalMask-background')};
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  overflow-y: auto;
`

export const Header = styled.header`
  padding: ${MODAL_PADDING};
  padding-bottom: 0;
`

export const Title = styled.h2`
  font-weight: ${font('weight', 'medium')};
`

export const ModalForm = styled.form`
  display: grid;
  grid-row-gap: 1rem;
`

export const Subtitle = styled.h3``

export const Footer = styled.footer`
  padding: 0 ${MODAL_PADDING};
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  grid-auto-columns: min-content;
`

export const Section = styled.section`
  padding: 0 ${MODAL_PADDING};
`
