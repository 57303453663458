import { motion, AnimatePresence } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

import getStyle from 'happitu/src/getStyle'
import { zIndex } from 'theme'

const Wrapper = styled(motion.div)`
  background: ${getStyle('modalMask-background')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndex('modal')};
`

export default function MaskWrapper(props: {
  isVisible: boolean
  onClick?: React.MouseEventHandler
}) {
  return (
    <AnimatePresence>
      {props.isVisible && (
        <Wrapper
          key="mask"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={props.onClick}
        />
      )}
    </AnimatePresence>
  )
}
