import styled from 'styled-components'

import { color } from 'theme'

export default styled.li`
  margin: .5em 0;
  padding-top: 1em;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid ${ color('border') };
    padding-bottom: 2em;
  }

  h4 {
    color: ${ color('grey') };
  }
`
