import { startCase } from 'lodash'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import Modal from '../Modal'
import { BaseModalProps } from '../Modal.types'

import { OnSelectChanges } from './RecordSelectModal.types'

import SearchInput from 'happitu/src/components/Fields/SearchInput'
import { RecordSelector, RecordSelectorProps } from 'happitu/src/components/Lists'
import { Button } from 'happitu/src/components/button'
import getStyle from 'happitu/src/getStyle'
import { GeneratedCollection } from 'happitu/src/helpers/generatedMetadata'

interface Props extends BaseModalProps, Omit<RecordSelectorProps, 'onSelect'> {
  collectionName: GeneratedCollection
  onSelect: (changes: OnSelectChanges) => void
  value: ID[]
  submitLabel?: string
}

const RecordSelectModal = ({
  className,
  isVisible,
  onClose,
  onSelect,
  value,
  submitLabel,
  multiple = true,
  ...selectProps
}: Props) => {
  const [ids, setIds] = useState(value)
  const humanizedCollectionName = useMemo(() => startCase(selectProps.collectionName), [
    selectProps.collectionName,
  ])

  const handleSubmit = () => {
    onSelect({
      add: ids.filter((id) => !value?.includes(id)),
      remove: value.filter((id) => !ids.includes(id)),
    })
  }

  // Guarantee that the value will be an array.
  // TODO: Decide if we want to handle this with the SelectableListProvider (always returning an array), and update every usage in the client if so.
  const handleSelect = (newValue: ID | ID[]) => {
    if (Array.isArray(newValue)) setIds(newValue)
    else if (!!newValue) setIds([newValue])
    else setIds([])
  }

  // Reset values when the modal is reopened.
  React.useEffect(() => {
    if (isVisible) {
      setIds(value)
    }
  }, [isVisible])

  return (
    <Modal isVisible={isVisible} onClose={onClose} className={className} size="small">
      <RecordSelector
        {...selectProps}
        multiple={multiple}
        value={ids}
        onSelect={handleSelect}
      />
      <footer>
        <Button primary onClick={handleSubmit}>
          {submitLabel || `Apply ${humanizedCollectionName}`}
        </Button>
      </footer>
    </Modal>
  )
}

export default styled(RecordSelectModal)`
  width: 100%;
  max-width: 400px;
  min-height: 200px;

  ${RecordSelector} {
    border-bottom: 1px solid ${getStyle('border-color')};

    > .search {
      border-top-left-radius: ${getStyle('modal-border-radius')};
      border-top-right-radius: ${getStyle('modal-border-radius')};
    }
  }

  ${SearchInput} {
    border: 0;
    border-bottom: 1px solid ${getStyle('border-color')};
    margin: 0;
    padding: 0.5em 0;
    font-size: 1.2em;
  }

  > footer {
    padding: 1em;
    padding-top: 0;
    display: grid;
    grid-template-columns: 1fr;
  }
`
