import { size as psize } from 'polished'
import * as React from 'react'
import styled, { css } from 'styled-components'

import Icon from 'happitu/src/components/Icon'
import getStyle from 'happitu/src/getStyle'
import { getFileUrl } from 'happitu/src/helpers/fileUploadHelpers'
import { font } from 'theme'

export type Sizes = 'small' | 'large' | undefined

interface BaseProps extends React.HTMLAttributes<HTMLElement> {
  size?: Sizes

  theme: Theme
  indicator?: boolean
}

interface Props extends BaseProps {
  record?: UserRecord
}

const sizeToPx = (size: Sizes) => {
  switch (size) {
    case 'small':
      return '24px'
    case 'large':
      return '100px'
    default:
      return '32px'
  }
}

export const avatarSize = ({ size }: Props) => psize(sizeToPx(size))

const avatarFontSize = ({ size }: Props) => {
  switch (size) {
    case 'small':
      return '10px'
    case 'large':
      return '32px'
    default:
      return '12px'
  }
}

const avatarBackground = ({ record, size }: Props) =>
  record && record.avatarFileId
    ? {
        backgroundImage: `url('${getFileUrl(record.avatarFileId)}')`,
        backgroundSize: sizeToPx(size),
      }
    : {}

const avatarContent = (props: Props) => {
  return props.record && props.record.avatarFileId ? null : getInitials(props)
}

// note the record.lastName check. the system user has an empty last name
const getInitials = ({ record }: Props) =>
  record ? `${record.firstName[0]}${record.lastName ? record.lastName[0] : ''}` : ''

const getStatusColor = ({ record }: Props) =>
  record && record.online ? getStyle('text-success-color') : getStyle('text-light-color')

const Avatar = styled.i.attrs((props: Props) => ({
  title: props.record ? `${props.record.firstName} ${props.record.lastName}` : '',
}))<Props>`
  align-items: center;
  background: ${getStyle('avatar-default-background')};
  border-radius: 50px;
  color: white;
  display: flex;
  flex-shrink: 0;
  font-style: normal;
  padding-right: 0;
  position: relative;
  text-align: center;
  ${avatarSize}

  &:before {
    content: '${avatarContent}';
    display: block;
    font-size: ${avatarFontSize};
    font-weight: ${font('weight', 'medium')};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:after {
    ${(props: Props) =>
      props.size !== 'small' &&
      props.size !== 'large' &&
      props.indicator !== false &&
      css`
        background-color: ${getStatusColor};
        border-radius: 7px;
        border: 2px solid ${getStyle('app-background')};
        bottom: -1px;
        content: '';
        display: inline-block;
        height: 10px;
        position: absolute;
        right: -1px;
        transform: translateY(2px);
        width: 10px;
        display: none;
      `}
  }

  @media print {
    display: none;
  }
`
function SmartAvatar({ style, ...props }: Props) {
  return props.record ? (
    <Avatar {...props} style={{ ...style, ...avatarBackground(props) }} />
  ) : (
    <EmptyAvatar {...props} />
  )
}

export default styled(SmartAvatar)<Props>``

export const DisabledAvatar = styled(Avatar)`
  cursor: default;
`

export const UserIcon = styled(Icon)`
  position: absolute;
  top: 22%;
  left: 22%;
`

export const EmptyAvatar = styled.i`
  border-radius: 50px;
  border: 2px dashed ${getStyle('text-light-color')};
  color: ${getStyle('text-light-color')};
  display: block;
  font-style: normal;
  position: relative;
  ${avatarSize}

  &:after {
    content: '\\e92a';
    display: block;
    font-family: 'icomoon';
    left: 22%;
    position: absolute;
    top: 22%;
  }
`
