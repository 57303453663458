import { pick } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import {
  ErrorFigure,
  FieldFigure,
  NextFieldWrapper,
  InsetLabelContent,
  InputWrapper,
} from '../../Fields/FieldUtils'
import NumInput, { NumInputProps } from '../../Fields/num-input'
import { FieldProps } from '../FinalFormFields.types'

const NumInputField = ({
  label,
  footnote,
  meta,
  input,
  ...props
}: FieldProps<number | null> & NumInputProps) => {
  // This is more verbose than using lodash.pick, but it's type-safe.
  // prettier-ignore
  // const numInputProps: Partial<NumInputProps> = (({min, max, allowBlank, initialValue, placeholder}: NumInputProps) =>
  //   ({min, max, allowBlank, initialValue, placeholder}))(props)
  const numInputProps: Partial<NumInputProps> = pick(props, [
    'allowBlank',
    'disabled',
    'max',
    'min',
    'placeholder',
  ])

  return (
    <NextFieldWrapper
      invalid={meta.touched && meta.error}
      className={props.className}
      style={props.style}
    >
      <InputWrapper>
        <NumInput
          {...numInputProps}
          initialValue={input.value}
          onBlur={input.onBlur}
          onChange={input.onChange}
          onCommit={input.onChange}
        />
        {label && <InsetLabelContent>{label}</InsetLabelContent>}
      </InputWrapper>
      {meta.touched && meta.error ? (
        <ErrorFigure>{meta.error}</ErrorFigure>
      ) : (
        <FieldFigure>{footnote}</FieldFigure>
      )}
    </NextFieldWrapper>
  )
}

export default styled(NumInputField)``
