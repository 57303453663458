export * from './InputFormModal/InputFormModal'
export * from './RecordSelectModal'
import Modal from './Modal'
export { default as PasswordPrompt } from './PasswordPrompt'
export { default as LineItem } from './LineItem'
export { default as ModalSection } from './ModalSection' // TODO: Deprecate, use new styles.
export { default as ModalSubHeader } from './ModalSubHeader' // TODO: Deprecate, use new styles.
export * from './Modal.styles'

export default Modal
