import update from 'immutability-helper'
import React, {
  ChangeEvent,
  KeyboardEvent,
  ClipboardEvent,
  useState,
  useRef,
} from 'react'
import AutosizeTextarea from 'react-textarea-autosize'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { FieldProps } from '../FinalFormFields.types'

import {
  ErrorFigure,
  FieldFigure,
  InputCSS,
  InputWrapper,
  InsetLabelContent,
  NextFieldWrapper,
  InputFocusCSS,
} from 'happitu/src/components/Fields/FieldUtils'
import { Badge } from 'happitu/src/components/badges'
import { emailValidator } from 'happitu/src/constants/validators'
import getStyle from 'happitu/src/getStyle'
import { getOrganization } from 'happitu/src/helpers/currentUserHelpers'
import { pluralize } from 'happitu/src/helpers/stringHelper'
import { Hotkey } from 'happitu/src/hooks/useHotkey'

const match = /[,\s;|]/
const hasEmail = /([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+))/

const validateEmail = emailValidator()
export const validate = (value: string[] | null = []) => {
  const errs = Array.isArray(value)
    ? value.reduce((acc, v, index) => {
        return !!validateEmail(v) ? [...acc, index] : acc
      }, [])
    : []
  return errs.length ? errs : null
}

export const requireEmails = (value?: string[]) => {
  return !value?.length ? 'At least one email is required.' : validate(value)
}

const BulkEmailField = (props: FieldProps<string[]>) => {
  const [value, setValue] = useState('')
  const [focus, setFocus] = useState(false)
  const placeholder = props.placeholder || `name@${getOrganization().nameSlug}.com`
  const emails = Array.isArray(props.input.value) ? props.input.value : []
  const input = useRef<HTMLTextAreaElement>(null)

  const appendEmail = (emailStr: string) => {
    const newEmails = emailStr
      .split(match)
      .filter((e) => e)
      .map((e) => e.trim().toLowerCase())
    props.input.onChange([...emails, ...newEmails])
    setValue('')
  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value)
  }

  const handlePaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
    const emails = e.clipboardData.getData('Text')
    appendEmail(emails)
    setValue('')
    e.preventDefault()
  }

  const handleRemove = (index: number) => {
    props.input.onChange(update(emails, { $splice: [[index, 1]] }))
  }

  const handleCleanup = () => {
    props.input.onChange(emails.filter((email) => !validateEmail(email)))
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    switch (e.key) {
      case Hotkey.Backspace:
        return (
          e.currentTarget.value === '' &&
          props.input.onChange(update(emails, { $splice: [[emails.length - 1, 1]] }))
        )
      case Hotkey.Comma:
      case Hotkey.Space:
      case Hotkey.Pipe:
        if (hasEmail.test(e.currentTarget.value)) {
          e.preventDefault()
          appendEmail(e.currentTarget.value)
        }
        return

      case Hotkey.Enter:
        e.preventDefault()
        return e.currentTarget.value !== '' && appendEmail(e.currentTarget.value)
    }
  }

  const handleBlur = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setFocus(false)

    if (e.currentTarget.value !== '') appendEmail(e.currentTarget.value)
  }

  return (
    <NextFieldWrapper className={props.className} style={props.style}>
      <InputWrapper as="div" onClick={() => input.current?.focus()}>
        <FauxInput focus={focus}>
          {emails.map((value, index) => (
            <Badge
              key={index}
              warn={props.meta.error && props.meta.error.includes(index)}
              onClick={() => {
                handleRemove(index)
              }}
            >
              {value}
            </Badge>
          ))}

          <AutosizeTextarea
            style={{ whiteSpace: 'nowrap', flexGrow: 1, padding: 0 }}
            onChange={handleChange}
            onPaste={handlePaste}
            onKeyDown={handleKeyDown}
            value={value}
            onFocus={() => setFocus(true)}
            onBlur={handleBlur}
            autoFocus={props.autoFocus}
            name={props.input.name}
            // @ts-ignore
            ref={input}
          />

          {emails.length === 0 && !value && <Placeholder>{placeholder}</Placeholder>}
        </FauxInput>

        {!!props.label && (
          <InsetLabelContent required={props.required}>{props.label}</InsetLabelContent>
        )}
      </InputWrapper>
      {Array.isArray(props.meta.error) ? (
        <ErrorFigure>
          There's {props.meta.error.length} invalid{' '}
          {pluralize('email', props.meta.error.length)}.{' '}
          <a onClick={handleCleanup}>Cleanup the invalid ones</a>
        </ErrorFigure>
      ) : props.meta.touched && props.meta.error ? (
        <ErrorFigure>{props.meta.error}</ErrorFigure>
      ) : (
        <FieldFigure>{props.footnote}</FieldFigure>
      )}
    </NextFieldWrapper>
  )
}

const FauxInput = styled.div<{ focus: boolean }>`
  ${InputCSS}
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  min-height: 4em;
  padding-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;

  ${ifProp('focus', InputFocusCSS)}

  > * {
    margin-right: 0.5em;
    margin-bottom: 8px;
  }

  textarea {
    display: inline-block;
    box-shadow: none;
    border: 0;
    padding: 8px 0;
    white-space: nowrap;
    resize: none;
  }
`

const Placeholder = styled.span`
  color: ${getStyle('input-default-placeholderColor')};
  left: 10px;
  position: absolute;
  top: 50%;
  user-select: none;
`

export default BulkEmailField
