import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { color } from 'theme'

export const Params = styled.ul`
  margin-bottom: 40px;
  margin-top: 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5em;
`

export const Param = styled.li<{ valid?: boolean }>`
  &:before {
    background: ${ifProp(
      'valid',
      color('secondary'),
      color('grey', 'lightest'),
    )};
    border-radius: 5px;
    content: '';
    display: inline-block;
    height: 10px;
    margin-right: 10px;
    width: 10px;
  }
`
