import React from 'react'

import { FieldProps } from '../FinalFormFields.types'

import TextField from './TextField'

const PasswordField = (props: FieldProps<string>) => {
  return <TextField {...props} type="password" />
}

export default PasswordField
